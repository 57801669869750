<template>
  <div>
    <b-card
      no-body
      class="liveinfo-form"
    >
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="10"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-form-datepicker
              v-model="query.createDateFr"
              class="el-def"
              placeholder="등록일(Fr)"
            />
            <label class="ml-25 mr-50">~</label>
            <b-form-datepicker
              v-model="query.createDateTo"
              class="el-def"
              placeholder="등록일(To)"
            />
          </b-col>
          <!-- Search -->
          <b-col
            cols="12"
            md="2"
            class="d-flex align-items-center justify-content-end"
          >
            <b-button
              variant="primary"
              class="btn-search"
              @click.prevent="refetchData()"
            >
              조회
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-card
      no-body
    >
      <b-table
        ref="refSuggestTable"
        primary-key="id"
        :items="fetchSuggests"
        :fields="tableColumns"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDesc"
        responsive
        sticky-header
        selectable
        select-mode="single"
        class="position-relative"
        @row-selected="onRowSelectedSuggest"
      >
        <template #cell(createDate)="data">
          <span class="text-nowrap">
            {{ data.value | $dateFormatter('YYYY-MM-DD HH:mm') }}
          </span>
        </template>
        <template #cell(suggestUserName)="data">
          <span class="text-nowrap">
            {{ data.value }}
          </span>
        </template>
      </b-table>
    </b-card>

    <b-card
      class="mt-2"
    >
      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="작성자"
            label-for="suggest-user-name"
          >
            <b-form-input
              id="suggest-user-name"
              v-model="suggestData.userName"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="로그인ID"
            label-for="suggest-user-login-id"
          >
            <b-form-input
              id="suggest-user-login-id"
              v-model="suggestData.userLoginId"
              readonly
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="연락처"
            label-for="suggest-phone-number"
          >
            <b-form-input
              id="suggest-phone-number"
              v-model="suggestData.phoneNumber"
              readonly
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          md="12"
        >
          <b-form-group
            label="내용"
            label-for="suggest-text"
          >
            <b-form-textarea
              v-model="suggestData.suggestText"
              rows="8"
              readonly
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="게시일"
            label-for="suggest-create-date"
          >
            <b-form-input
              id="suggest-create-date"
              v-model="suggestData.createDate"
              readonly
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import axios from '@axios'
import dayjs from 'dayjs'
import { ref } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
  },

  setup() {
    const toast = useToast()

    const suggestData = ref({
      id: null,
      userLoginId: null,
      userName: null,
      phoneNumber: null,
      suggestText: null,
      createDate: null,
    })

    // Query Data
    const query = ref({
      createDateFr: dayjs().add(-3, 'month').format('YYYY-MM-DD'),
      createDateTo: dayjs().format('YYYY-MM-DD'),
    })
    // Query Data End.

    // Main Table Config
    const refSuggestTable = ref(null)
    const sortBy = ref('id')
    const isSortDesc = ref(true)
    const totalRecords = ref(0)
    const tableColumns = [
      { key: 'id', label: 'Id', sortable: true },
      { key: 'suggestText', label: '제안내용', sortable: true },
      { key: 'createDate', label: '등록일시', sortable: true },
      { key: 'suggestUserName', label: '제안자', sortable: true },
    ]
    const refetchData = () => {
      refSuggestTable.value.refresh()
    }
    const onRowSelectedSuggest = items => {
      const item = items[0]
      fetchSuggest(item.id)
    }
    // Main Table Config End.

    const fetchSuggest = id => {
      axios.get(`/fa/poll-suggest/${id}`)
        .then(rs => {
          suggestData.value = rs.data
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: '데이터 조회에 실패하였습니다.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const fetchSuggests = (ctx, callback) => {
      axios.post('/fa/poll-suggest/list', {
        search: {
          createDateFr: query.value.createDateFr ? `${query.value.createDateFr}T00:00:00` : null,
          createDateTo: query.value.createDateTo ? `${query.value.createDateTo}T23:59:59` : null,
        },
        sort: {
          predicate: sortBy.value,
          reverse: isSortDesc.value,
        },
      })
        .then(rs => {
          const { items, totalRecord } = rs.data
          callback(items)
          totalRecords.value = totalRecord
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: '데이터 조회에 실패하였습니다.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    return {
      query,
      suggestData,
      tableColumns,
      refSuggestTable,
      sortBy,
      isSortDesc,
      refetchData,
      totalRecords,
      fetchSuggests,
      onRowSelectedSuggest,
    }
  },
}
</script>
