var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-card", { staticClass: "liveinfo-form", attrs: { "no-body": "" } }, [
        _c(
          "div",
          { staticClass: "m-2" },
          [
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  {
                    staticClass:
                      "d-flex align-items-center justify-content-start mb-1 mb-md-0",
                    attrs: { cols: "12", md: "10" },
                  },
                  [
                    _c("b-form-datepicker", {
                      staticClass: "el-def",
                      attrs: { placeholder: "등록일(Fr)" },
                      model: {
                        value: _vm.query.createDateFr,
                        callback: function ($$v) {
                          _vm.$set(_vm.query, "createDateFr", $$v)
                        },
                        expression: "query.createDateFr",
                      },
                    }),
                    _c("label", { staticClass: "ml-25 mr-50" }, [_vm._v("~")]),
                    _c("b-form-datepicker", {
                      staticClass: "el-def",
                      attrs: { placeholder: "등록일(To)" },
                      model: {
                        value: _vm.query.createDateTo,
                        callback: function ($$v) {
                          _vm.$set(_vm.query, "createDateTo", $$v)
                        },
                        expression: "query.createDateTo",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "b-col",
                  {
                    staticClass:
                      "d-flex align-items-center justify-content-end",
                    attrs: { cols: "12", md: "2" },
                  },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "btn-search",
                        attrs: { variant: "primary" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.refetchData()
                          },
                        },
                      },
                      [_vm._v(" 조회 ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "b-card",
        { attrs: { "no-body": "" } },
        [
          _c("b-table", {
            ref: "refSuggestTable",
            staticClass: "position-relative",
            attrs: {
              "primary-key": "id",
              items: _vm.fetchSuggests,
              fields: _vm.tableColumns,
              "sort-by": _vm.sortBy,
              "sort-desc": _vm.isSortDesc,
              responsive: "",
              "sticky-header": "",
              selectable: "",
              "select-mode": "single",
            },
            on: {
              "update:sortBy": function ($event) {
                _vm.sortBy = $event
              },
              "update:sort-by": function ($event) {
                _vm.sortBy = $event
              },
              "update:sortDesc": function ($event) {
                _vm.isSortDesc = $event
              },
              "update:sort-desc": function ($event) {
                _vm.isSortDesc = $event
              },
              "row-selected": _vm.onRowSelectedSuggest,
            },
            scopedSlots: _vm._u([
              {
                key: "cell(createDate)",
                fn: function (data) {
                  return [
                    _c("span", { staticClass: "text-nowrap" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("$dateFormatter")(
                              data.value,
                              "YYYY-MM-DD HH:mm"
                            )
                          ) +
                          " "
                      ),
                    ]),
                  ]
                },
              },
              {
                key: "cell(suggestUserName)",
                fn: function (data) {
                  return [
                    _c("span", { staticClass: "text-nowrap" }, [
                      _vm._v(" " + _vm._s(data.value) + " "),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "b-card",
        { staticClass: "mt-2" },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "12", md: "6" } },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "작성자",
                        "label-for": "suggest-user-name",
                      },
                    },
                    [
                      _c("b-form-input", {
                        attrs: { id: "suggest-user-name", readonly: "" },
                        model: {
                          value: _vm.suggestData.userName,
                          callback: function ($$v) {
                            _vm.$set(_vm.suggestData, "userName", $$v)
                          },
                          expression: "suggestData.userName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "12", md: "6" } },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "로그인ID",
                        "label-for": "suggest-user-login-id",
                      },
                    },
                    [
                      _c("b-form-input", {
                        attrs: { id: "suggest-user-login-id", readonly: "" },
                        model: {
                          value: _vm.suggestData.userLoginId,
                          callback: function ($$v) {
                            _vm.$set(_vm.suggestData, "userLoginId", $$v)
                          },
                          expression: "suggestData.userLoginId",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "12", md: "6" } },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "연락처",
                        "label-for": "suggest-phone-number",
                      },
                    },
                    [
                      _c("b-form-input", {
                        attrs: { id: "suggest-phone-number", readonly: "" },
                        model: {
                          value: _vm.suggestData.phoneNumber,
                          callback: function ($$v) {
                            _vm.$set(_vm.suggestData, "phoneNumber", $$v)
                          },
                          expression: "suggestData.phoneNumber",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "12", md: "12" } },
                [
                  _c(
                    "b-form-group",
                    { attrs: { label: "내용", "label-for": "suggest-text" } },
                    [
                      _c("b-form-textarea", {
                        attrs: { rows: "8", readonly: "" },
                        model: {
                          value: _vm.suggestData.suggestText,
                          callback: function ($$v) {
                            _vm.$set(_vm.suggestData, "suggestText", $$v)
                          },
                          expression: "suggestData.suggestText",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "12", md: "6" } },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "게시일",
                        "label-for": "suggest-create-date",
                      },
                    },
                    [
                      _c("b-form-input", {
                        attrs: { id: "suggest-create-date", readonly: "" },
                        model: {
                          value: _vm.suggestData.createDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.suggestData, "createDate", $$v)
                          },
                          expression: "suggestData.createDate",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }